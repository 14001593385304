<template>
  <div>
    <el-form ref="objForm" :model="objInfo" size="small" label-width="100px">
      <el-form-item label="对象名称:" prop="name">
        <el-input v-model="objInfo.name" placeholder="请输入对象名称:" clearable :style="{ width: '80%' }"> </el-input>
      </el-form-item>
      <el-form-item label="河湖类型:" prop="rlType">
        <el-radio-group v-model="objInfo.rlType" size="medium" @change="changeType">
          <el-radio v-for="(item, index) in typeOptions" :key="index" :label="item.value" :disabled="item.disabled">{{
            item.label
          }}</el-radio>
        </el-radio-group>
      </el-form-item>
      <el-form-item v-if="objInfo.rlType && objInfo.rlType === 1" label="河流长度:" prop="length">
        <el-input v-model="objInfo.length" placeholder="请输入河流长度:" clearable :style="{ width: '80%' }"></el-input>
        m
      </el-form-item>
      <el-form-item v-if="objInfo.rlType && objInfo.rlType !== 1" label="面积:" prop="area">
        <el-input v-model="objInfo.area" placeholder="请输入湖泊面积:" clearable :style="{ width: '80%' }"> </el-input>
        m<sup>2</sup>
      </el-form-item>
      <el-form-item v-if="objInfo.rlType && objInfo.rlType !== 1" label="库容:" prop="volume">
        <el-input v-model="objInfo.volume" placeholder="请输入库容:" clearable :style="{ width: '80%' }"></el-input>
        万m<sup>3</sup>
      </el-form-item>

      <el-form-item label="描述:" prop="comments">
        <!-- <el-input
          v-model="objInfo.comments"
          type="textarea"
          placeholder="河湖对象基础信息描述:"
          :autosize="{ minRows: 4, maxRows: 4 }"
          :style="{ width: '80%' }"
        ></el-input> -->
        <quill-editor :content="objInfo.comments" :options="editorOption" @change="onEditorChange($event)" />
      </el-form-item>
    </el-form>
  </div>
</template>
<script>
  import 'quill/dist/quill.core.css'
  import 'quill/dist/quill.snow.css'
  import 'quill/dist/quill.bubble.css'
  import { quillEditor } from 'vue-quill-editor'
  export default {
    name: '',
    components: {
      quillEditor
    },
    props: {
      objInfo: {
        type: Object,
        default: null
      }
    },
    data() {
      return {
        typeOptions: [
          {
            label: '河流',
            value: 1
          },
          {
            label: '湖泊',
            value: 2
          },
          {
            label: '水库',
            value: 3
          }
        ],
        editorOption: {
          modules: {
            // Some Quill toolbar options...
            toolbar: [[{ color: [] }, { background: [] }], ['bold', 'italic', 'underline', 'strike'], [{ align: [] }]]
          }
        }
      }
    },
    computed: {},
    watch: {
      objInfo: {
        handler(val) {
          // console.log('<==objInfo ==>', val)
        }
      }
    },
    created() {},
    mounted() {},
    methods: {
      // 河湖简介 富文本框
      onEditorChange({ quill, html, text }) {
        //   console.log("editor change!", quill, html, text);
        this.objInfo.comments = html
      },
      changeType(e) {
        // console.log(e)
      }
    }
  }
</script>
<style lang="less" scoped></style>
