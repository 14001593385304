<template>
  <div class="create-pro-page">
    <Map
      ref="mapCreatePro"
      @getPointWKT="getPointWKT"
      @getAreaWKT="getAreaWKT"
      @setPoint="setPoint"
      @setArea="setArea"
    ></Map>

    <div class="left-info-box">
      <!-- 项目名称 -->
      <div class="pro-container">
        <div>
          <el-select
            ref="select"
            v-model="proInfo.id"
            class="select"
            filterable
            @change="loadProInfo"
            @visible-change="(v) => visibleChange(v, 'select', addPro)"
          >
            <el-option v-for="(item, index) in proInfoList" :key="index" :label="item.name" :value="item.id">
            </el-option>
          </el-select>
        </div>
        <div class="ylan" @click="preview">预览</div>
        <img class="angle" src="../../../assets/icon/project/angle-icon.svg" alt="" srcset="" />
      </div>
      <img style="margin: 0 16px" src="../../../assets/icon/project/split-line.svg" alt="" srcset="" />
      <!-- <div class="pro-info">
        <div class="title-box">
          <MyTitle title="项目名称"></MyTitle>
          <div class="title-button-box">
            <span class="custom-btn" @click="preview">方案预览</span>
          </div>
        </div>
        <div class="por-info-box">
          <el-form ref="userForm" :model="proInfo" size="small" label-width="80px">
            <el-form-item label="名称:" prop="name">
              <el-input v-model="proInfo.name" clearable :style="{ width: '80%' }"></el-input>
            </el-form-item>
          </el-form>
        </div>
      </div> -->
      <!-- 基础信息 -->
      <div class="basic-info">
        <div class="title-box">
          <MyTitle title="基础信息" />
          <div class="title-button-box">
            <span v-if="!editFlag" class="custom-btn" @click="editFlag = true">编辑</span>
            <span v-else class="custom-btn" @click="submitBasic">保存</span>
          </div>
        </div>
        <div class="basic-info-box">
          <div v-if="addProFlag">
            <el-form ref="userForm" :model="proInfo" size="small" label-width="80px">
              <el-form-item label="名称:" prop="name">
                <el-input v-model="proInfo.name" clearable :style="{ width: '80%' }"></el-input>
              </el-form-item>
            </el-form>
          </div>
          <ProBasicForm ref="proBasicForm" :basic-info="basicInfo" :edit-flag="editFlag" />
        </div>
      </div>
      <!-- 评估对象 -->
      <div class="evaluation-obj">
        <div class="title-box">
          <MyTitle title="评估对象"></MyTitle>
        </div>
        <div class="evaluation-obj-box">
          <EvaluationObj
            ref="evaluationObj"
            :basic-info="basicInfo"
            @addMarkArea="addMarkArea"
            @saveAddAreaFrom="saveAddAreaFrom"
            @cancelAddAreaFrom="cancelAddAreaFrom"
            @saveAddAreaFrom2="saveAddAreaFrom2"
            @editFeature="editFeature"
            @handleSite="handleSite"
            @saveSiteInfo="saveSiteInfo"
            @dblclickEvaluationObj="dblclickEvaluationObj"
          />
        </div>
      </div>
    </div>

    <!-- 添加监测点 -->
    <div v-show="showSiteInfo" class="siteForm">
      <div class="siteFormTitle">
        监测点信息
        <img class="angle" src="../../../assets/icon/project/angle-icon.svg" alt="" srcset="" />
      </div>
      <img src="../../../assets/icon/project/split-line.svg" alt="" srcset="" />
      <SiteForm ref="siteForm" @saveSiteInfo="saveSiteInfo" @cancelAddPoint="cancelAddPoint" />
    </div>

    <!-- 方案预览 -->
    <el-dialog :visible.sync="previewDialog" top="80px" width="90%" append-to-body destroy-on-close>
      <div slot="title" class="preview-header-title">
        <h1>{{ proInfo.name }}</h1>
        <h4>(采样方案)</h4>
      </div>
      <ProviewPro :pro-info="proInfo" />
      <div style="display: flex; justify-content: center">
        <el-button class="btn-submit" style="width: 200px" type="primary" @click="confirmPreview">确认</el-button>
      </div>
    </el-dialog>
  </div>
</template>
<script>
  import MyTitle from '@/components/title'
  import Map from '@/components/map/mapBase'
  import ProBasicForm from './proBasicForm.vue'
  import EvaluationObj from './evaluationObj.vue'
  import SiteForm from './siteForm.vue'
  import ProviewPro from './previewPro.vue'
  // import { mapState } from 'vuex'

  import { Project } from '@/api/pro/index.js'

  export default {
    name: 'CreatePro',
    components: {
      MyTitle,
      Map,
      ProBasicForm,
      EvaluationObj,
      SiteForm,
      ProviewPro
    },
    props: {},
    data() {
      return {
        addProFlag: false, // 新增项目状态控制器
        editFlag: false, // 编辑基础信息状态控制器
        proInfoList: [],
        proInfo: {
          name: null
        },
        basicInfo: {
          year: null,
          region: '',
          standardId: null
        },
        previewDialog: false, // 方案预览控制器
        showSiteInfo: false // 展示站点信息控制器
      }
    },
    computed: {},
    watch: {},
    created() {
      this.loadPro()
    },
    mounted() {},
    methods: {
      /**
       * @description 加载项目列表数据
       * @author hu_ty
       * @since
       * @param {*}
       *
       */
      loadPro() {
        let param = {
          pageSize: 100
        }
        Project.getList(param).then((res) => {
          this.proInfoList = res.data.data.list
        })
      },
      /**
       * 为element-ui的Select和Cascader添加弹层底部操作按钮
       * @param visible
       * @param refName  设定的ref名称
       * @param onClick  底部操作按钮点击监听
       */
      visibleChange(visible, refName, onClick) {
        if (visible) {
          const ref = this.$refs[refName]
          let popper = ref.$refs.popper
          if (popper.$el) popper = popper.$el
          if (!Array.from(popper.children).some((v) => v.className === 'el-cascader-menu__list')) {
            const el = document.createElement('ul')
            el.className = 'el-cascader-menu__list'
            el.style = 'border-top: solid 1px #E4E7ED; padding:0; color: #606266;'
            el.innerHTML = `<li class="add-li" style="display: flex;text-align: center;align-items: center;height: 48px;color: rgb(64, 158, 255);font-size: 14px;">
          <span class="el-cascader-node__label">＋添加项目</span>
          </li>`
            popper.appendChild(el)
            el.onclick = () => {
              // 底部按钮的点击事件 点击后想触发的逻辑也可以直接写在这
              onClick && onClick()

              // 以下代码实现点击后弹层隐藏 不需要可以删掉
              if (ref.toggleDropDownVisible) {
                ref.toggleDropDownVisible(false)
              } else {
                ref.visible = false
              }
            }
          }
        }
      },
      /**
       * @description  新增项目
       * @author hu_ty
       * @since
       * @param {*}
       *
       */
      addPro() {
        this.$message.success('新增项目')
        this.addProFlag = true
        this.editFlag = true
        this.proInfo = {}
        this.basicInfo = {}

        //除去 已有面、点
        this.$refs.mapCreatePro.map.removeLayer(this.$refs.mapCreatePro.pointsLayer)
        this.$refs.mapCreatePro.map.removeLayer(this.$refs.mapCreatePro.areasLayer)
      },
      /**
       * @description 依照选取的项目进行信息的派发
       * @author hu_ty
       * @since
       * @param {*}
       *
       */
      loadProInfo(val) {
        const i = this.proInfoList.find((i) => {
          return i.id == val
        })
        this.proInfo.name = i.name
        // this.proInfo = JSON.parse(JSON.stringify(i))
        // this.basicInfo = {
        //   year: i.year,
        //   region: i.region,
        //   standardId: i.standardId
        // }
        this.basicInfo = JSON.parse(JSON.stringify(i))
      },
      //基础信息内容保存,并将数据动态传递给评估对象组件
      submitBasic() {
        this.basicInfo = this.$refs.proBasicForm.basicForm
        let param = Object.assign(this.basicInfo, this.proInfo)
        let chooseUserIds = []
        if (param.chooseUsers && param.chooseUsers.length > 0) {
          param.chooseUsers.forEach((i) => {
            chooseUserIds.push(i.id)
          })
        }

        param.chooseUserIds = chooseUserIds.toString()
        try {
          Project.savePro(param).then((res) => {
            if (this.addProFlag) {
              this.loadPro()
              this.proInfo.id = res.data.data.id
              this.addProFlag = false
            }

            this.basicInfo.id = res.data.data.id
            //传递给评估对象组件
            this.$refs.evaluationObj.setBasicInfo(this.basicInfo)
            this.$message({
              type: 'success',
              message: '基础信息保存成功'
            })
            this.editFlag = false
          })
        } catch (error) {
          console.log('savePro', error)
        }
      },
      //双击对象事件
      dblclickEvaluationObj(row) {
        //objId 查询所有监测点
        let data = {
          pageNum: 1,
          pageSize: 860601,
          objId: row.id
        }
        Project.getAllJianCeDian(data).then((res) => {
          console.log('res.data.data.list', res.data.data.list)
          //地图展示监测点
          this.$refs.mapCreatePro.showMarkPoints(res.data.data.list)
        })
        this.$refs.mapCreatePro.toNewView(row)
      },

      /*
       *
       *          ┌─┐       ┌─┐
       *       ┌──┘ ┴───────┘ ┴──┐
       *       │                 │
       *       │       ───       │
       *       │  ─┬┘       └┬─  │
       *       │                 │
       *       │       ─┴─       │
       *       │                 │
       *       └───┐         ┌───┘
       *           │         │
       *           │         │
       *           │         │
       *           │         └──────────────┐
       *           │                        │
       *           │                        ├─┐
       *           │                        ┌─┘
       *           │                        │
       *           └─┐  ┐  ┌───────┬──┐  ┌──┘
       *             │ ─┤ ─┤       │ ─┤ ─┤
       *             └──┴──┘       └──┴──┘
       *                      面
       */
      //调用地图画面操作
      addMarkArea() {
        this.$refs.mapCreatePro.addMarkArea()
      },
      //设置对象面位置信息
      getAreaWKT(wkt) {
        this.$refs.evaluationObj.objInfo.geom = wkt
      },
      //取消对象画面操作
      cancelAddAreaFrom() {
        this.$refs.mapCreatePro.cancelAddAreaFrom()
        this.showloadData()
      },
      showloadData() {
        let data = {
          pageNum: 1,
          pageSize: 100,
          proId: this.basicInfo.id
        }
        Project.getAllScheme(data).then((res) => {
          let objTableData = []
          res.data.data.list.forEach((element) => {
            if (element.obj) {
              element.obj.sID = element.id
              element.obj.choseUser = element.userIds
            }
            objTableData.push(element.obj)
          })
          this.objTableData = objTableData
          this.$refs.mapCreatePro.saveAddAreaFrom()
          this.$refs.mapCreatePro.showMarkArea(this.objTableData)
        })
      },
      //保存添加对象时所画的面  同时在地图展示新的objTableData
      saveAddAreaFrom(objTableData) {
        this.$refs.mapCreatePro.saveAddAreaFrom()
        //取消展示点操作
        this.$refs.mapCreatePro.map.removeLayer(this.$refs.mapCreatePro.pointsLayer)
        this.$refs.mapCreatePro.showMarkArea(objTableData)
      },
      saveAddAreaFrom2(objTableData) {
        this.$refs.mapCreatePro.saveAddAreaFrom()
        //取消操作
        this.$refs.mapCreatePro.showMarkArea(objTableData)
      },

      /*
       *
       *          ┌─┐       ┌─┐
       *       ┌──┘ ┴───────┘ ┴──┐
       *       │                 │
       *       │       ───       │
       *       │  ─┬┘       └┬─  │
       *       │                 │
       *       │       ─┴─       │
       *       │                 │
       *       └───┐         ┌───┘
       *           │         │
       *           │         │
       *           │         │
       *           │         └──────────────┐
       *           │                        │
       *           │                        ├─┐
       *           │                        ┌─┘
       *           │                        │
       *           └─┐  ┐  ┌───────┬──┐  ┌──┘
       *             │ ─┤ ─┤       │ ─┤ ─┤
       *             └──┴──┘       └──┴──┘
       *                      点
       */
      //点击对象添加监测点
      handleSite(data) {
        this.$refs.siteForm.siteForm = {}
        this.$refs.siteForm.initData()
        this.showSiteInfo = true
        this.$refs.siteForm.objInfo = JSON.parse(JSON.stringify(data))
        this.$refs.siteForm.proId = this.basicInfo.id
        this.$refs.mapCreatePro.addMarkPoint()
      },
      //取消添加监测点地图操作
      cancelAddPoint() {
        this.$refs.siteForm.siteForm = {}
        this.showSiteInfo = false
        this.$refs.mapCreatePro.cancelAddPointFrom()
      },
      //保存监测点信息到对象上
      saveSiteInfo(data) {
        this.cancelAddPoint()
        this.$refs.mapCreatePro.showMarkPoints(data)
      },
      //获取监测点经纬度
      getPointWKT(data) {
        this.$refs.siteForm.siteForm.geom = data.wkt
        this.$set(this.$refs.siteForm.siteForm, 'lon', data.coordinate[0])
        this.$set(this.$refs.siteForm.siteForm, 'lat', data.coordinate[1])
      },

      //编辑点、面地理信息
      editFeature(obj) {
        this.$refs.siteForm.objInfo = JSON.parse(JSON.stringify(obj))
        this.$refs.mapCreatePro.editFeature(obj)
      },
      //编辑-点击了对象的监测点
      setPoint(data) {
        this.showSiteInfo = true
        this.$refs.siteForm.siteForm = data
      },
      //编辑-点击了对象的面
      setArea() {
        //隐藏点 如果点表单在显示状态
        if (this.showSiteInfo) {
          this.showSiteInfo = false
          this.$refs.siteForm.siteForm = {}
        }
      },

      /*
       *
       *          ┌─┐       ┌─┐
       *       ┌──┘ ┴───────┘ ┴──┐
       *       │                 │
       *       │       ───       │
       *       │  ─┬┘       └┬─  │
       *       │                 │
       *       │       ─┴─       │
       *       │                 │
       *       └───┐         ┌───┘
       *           │         │
       *           │         │
       *           │         │
       *           │         └──────────────┐
       *           │                        │
       *           │                        ├─┐
       *           │                        ┌─┘
       *           │                        │
       *           └─┐  ┐  ┌───────┬──┐  ┌──┘
       *             │ ─┤ ─┤       │ ─┤ ─┤
       *             └──┴──┘       └──┴──┘
       *
       */
      //方案预览
      preview() {
        this.previewDialog = true
      },
      //方案预览确认按钮
      confirmPreview() {
        this.$notify({
          type: 'info',
          message: '采样方案信息确认'
        })
        this.previewDialog = !this.previewDialog
      }
    }
  }
</script>
<style lang="less" scoped>
  .create-pro-page {
    width: 100%;
    height: 100%;
    position: relative;
    color: #000;
    /deep/ .el-drawer.rtl {
      height: 89%;
      top: 86px;
      bottom: 0;
      right: 24px;
      .el-drawer__header {
        align-items: center;
        color: #72767b;
        display: flex;
        margin-bottom: 0px;
        padding: 24px 24px;
        font-size: large;
        font-weight: 600;
        border-bottom: 1px solid #eee;
      }
      .el-drawer__body {
        padding: 24px;
      }
    }
  }
  .left-info-box {
    background-color: rgb(255, 255, 255);
    position: absolute;
    left: 20px;
    top: 34px;
    width: 400px;
    height: calc(100% - 48px);
    display: flex;
    flex-direction: column;

    .pro-info {
      height: 12%;
      background-color: #fff;
      margin: 0 16px;
      overflow: hidden;
      .por-info-box {
        padding: 0 24px;
      }
    }
    .basic-info {
      background-color: #fff;
      margin: 0 16px;
      .basic-info-box {
        padding: 0 24px;
      }
    }
    .evaluation-obj {
      margin: 0 16px;
      flex: 1;
      background-color: #fff;
      overflow: hidden;
    }
  }
  .title-box {
    margin: 8px 0;
    height: 40px;
    line-height: 24px;
    align-items: center;
    display: flex;
    padding: 0 16px;

    justify-content: space-between;
    background: rgba(64, 158, 255, 0.1);
    backdrop-filter: blur(33px);

    .title-button-box {
      span {
        margin-right: 16px;
      }
    }
  }

  .info-label {
    margin-left: 120px;
  }
  .rigth-info-box {
    position: absolute;
    right: 20px;
    top: 34px;
    width: 500px;
    max-height: calc(100% - 48px);
    overflow: hidden;
    background-color: #fff;
  }

  .siteForm {
    width: 400px;
    height: 88%;
    overflow-y: scroll;
    padding: 16px 16px 0;
    background-color: white;
    position: fixed;
    top: 90px;
    right: 10px;
    z-index: 999;
    .siteFormTitle {
      height: 32px;
      color: rgba(0, 0, 0, 1);
      font-family: PingFang SC;
      font-weight: 700;
      font-size: 20px;
      position: relative;
      padding: 2px;
      .angle {
        position: absolute;
        top: 0;
        right: 0;
      }
    }
  }

  ::v-deep ::-webkit-scrollbar {
    display: none;
    scrollbar-width: none; /* firefox */
    -ms-overflow-style: none; /* IE 10+ */
  }

  .pro-container {
    position: relative;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: space-around;
    margin: 16px 16px 0;
    // .pro-title {
    //   color: rgba(0, 0, 0, 1);
    //   font-family: 'PingFang SC';
    //   font-weight: 500;
    //   font-size: 20px;
    // }
    .select {
      border: 0;
    }
    /deep/.el-input--suffix .el-input__inner {
      border: 0;
      font-weight: 700;
      font-size: 20px;
      width: 300px;
      color: rgb(0, 0, 0);
    }
    .ylan {
      text-align: right;
      color: rgba(64, 158, 255, 1);
      font-family: 'PingFang SC';
      font-size: 14px;
      line-height: 24px;
      cursor: pointer;
    }
    .angle {
      position: absolute;
      top: 0;
      right: 0;
    }
  }
  .pro-title:hover .list-container {
    display: block;
  }

  .list-container {
    position: absolute;
    display: none;
    top: 40px;
    // left: 20px;
    z-index: 1;
    width: 80%;
    border: 1px solid rgba(0, 0, 0, 0.1);
    ul {
      list-style: none;
      height: 130px;
      overflow: auto;
      background-color: rgba(255, 255, 255, 01);
      padding: 0;

      li {
        padding: 10px 40px;
      }
      li:hover {
        background-color: #eee;
      }
    }
  }

  /deep/ .add-li {
    display: flex;
    text-align: center;
    align-items: center;
    height: 48px;
    color: rgb(64, 158, 255);
    font-size: 14px;
  }

  .preview-header-title {
    text-align: center;
    h1 {
      color: rgba(0, 0, 0, 1);
      font-family: 'PingFang SC';
      font-weight: 500;
      font-size: 32px;
    }
    h4 {
      color: rgba(0, 0, 0, 0.6);
      font-family: 'PingFang SC';
      font-size: 20px;
    }
  }
</style>
