<template>
  <div v-if="choseStaffFlag">
    <el-table ref="multipleTable" :data="tableData" width="100%" @selection-change="handleSelectionChange">
      <el-table-column type="selection" width="55"> </el-table-column>
      <el-table-column prop="name" label="姓名"></el-table-column>
    </el-table>
  </div>
</template>
<script>
  import { mapState } from 'vuex'
  import { Project } from '@/api/pro/index.js'
  export default {
    name: '',
    components: {},
    props: {
      staffList: {
        type: Array,
        default: () => []
      },
      obj: {
        type: Object,
        default: () => {}
      },
      choseStaffFlag: {
        type: Boolean,
        default: false
      }
    },
    data() {
      return {
        selectedTarget: [],
        tableData: [],
        currentObj: {},
        staff: [],

        userIds: ''
      }
    },
    computed: {
      ...mapState({})
    },
    watch: {
      staffList: {
        immediate: true,
        handler(val) {
          this.tableData = val
        }
      },
      obj: {
        immediate: true,
        handler(val, oldval) {
          this.currentObj = val
        },
        deep: true
      },
      choseStaffFlag: {
        immediate: true,
        handler(val) {
          if (val) {
            if (this.obj.choseUser && this.obj.choseUser != '') {
              // this.tableData = this.staff
              this.loadStaff(this.obj.choseUser)
            }
          }
        }
      }
    },
    created() {},
    mounted() {},
    methods: {
      //选择人员
      handleSelectionChange(val) {
        this.selectedTarget = val
        this.currentObj.renyuan = val
      },
      //确认选择
      async choseStaffConfirm() {
        let ids = []
        this.currentObj.renyuan.forEach((element) => {
          ids.push(element.id)
        })
        //修改方案人员
        const param = {
          id: this.currentObj.sID,
          userIds: ids.toString()
        }
        await Project.saveScheme(param).then((res) => {
          console.log('saveScheme userIds', res)
          this.userIds = param.userIds
        })
      },
      loadStaff(data) {
        if (data && data != '') {
          let arr = data?.split(',')
          if (arr && arr.length > 0) {
            this.$nextTick(() => {
              this.tableData.forEach((row) => {
                const i = arr.findIndex((i) => {
                  return i == row.id
                })
                if (i != -1) {
                  this.$refs.multipleTable.toggleRowSelection(row, true)
                }
                // arr.forEach((i) => {
                //   if (i == row.id) {
                //     this.$refs.multipleTable.toggleRowSelection(row, true)
                //     console.log('1', i, row)
                //   } else {
                //     this.$refs.multipleTable.toggleRowSelection(row, false)
                //     console.log('2', i, row)
                //   }
                // })
              })
            })
          }
        }
      }
    }
  }
</script>
<style lang="less" scoped></style>
