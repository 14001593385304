<template>
  <div class="obj-info-page">
    <div class="add-box" @click="addObj">
      <span> <el-icon class="el-icon-plus" style="margin-right: 8px"></el-icon>添加对象 </span>
    </div>

    <div>
      <el-table
        :data="objTableData"
        style="width: 100%"
        :cell-style="cellStyle"
        height="400px"
        highlight-current-row
        header-cell-class-name="table-header-cls"
        @current-change="handleCurrentChange"
        @row-dblclick="dblclick"
      >
        <el-table-column prop="name" label="对象名称"> </el-table-column>
        <el-table-column prop="rlType" label="河湖类型" width="80" :formatter="rltypeIcon"> </el-table-column>
        <el-table-column fixed="right" label="操作" width="120">
          <template slot-scope="scope">
            <el-button class="edit-btn" type="text" size="small" @click="editObj(scope.row)">编辑</el-button>
            <el-dropdown class="operation-btn">
              <span class="el-dropdown-link"> 更多<i class="el-icon-arrow-down el-icon-d-arrow-right"></i> </span>
              <el-dropdown-menu slot="dropdown">
                <!-- <el-dropdown-item>
                  <el-button type="text" @click="choseTarget(scope.row)">可选指标项</el-button>
                </el-dropdown-item> -->
                <el-dropdown-item>
                  <el-button type="text" @click="handleSite(scope.row)">添加监测点</el-button>
                </el-dropdown-item>
                <el-dropdown-item>
                  <el-button type="text" @click="userSelect(scope.row)">人员选择</el-button>
                </el-dropdown-item>
                <el-dropdown-item>
                  <el-button type="text" @click="handleDelete(scope.row)">删除 </el-button>
                </el-dropdown-item>
              </el-dropdown-menu>
            </el-dropdown>
          </template>
        </el-table-column>
      </el-table>
    </div>

    <!-- 新增、编辑对象页 -->
    <div v-show="addObjFlag" class="ObjFrom">
      <div class="objFromTitle">
        {{ objTitle }}
        <img class="angle" src="../../../assets/icon/project/angle-icon.svg" alt="" srcset="" />
      </div>
      <img src="../../../assets/icon/project/split-line.svg" alt="" srcset="" />
      <ObjInfoForm ref="objInfoForm" :obj-info="objInfo" style="padding-top: 20px" />
      <div style="float: right; margin-right: 20px">
        <el-button class="btn-info" @click="objInfoCancel">取消</el-button>
        <el-button v-if="objTitle && objTitle === '编辑对象' && !objInfo.geom" class="btn-info" @click="addMapInfo">
          添加地理信息
        </el-button>
        <el-button class="btn-submit" type="primary" @click="objInfoConfirm">确定</el-button>
      </div>
    </div>

    <!-- no use 可选指标项 -->
    <el-dialog :visible.sync="choseTargetFlag" title="可选指标项" width="20%" destroy-on-close>
      <TargrtChoose ref="targrtChoose" />
      <div slot="footer">
        <el-button class="btn-info" @click="choseTargetFlag = false">取消</el-button>
        <el-button class="btn-submit" type="primary" @click="choseTargetConfirm">确定</el-button>
      </div>
    </el-dialog>

    <!-- 人员选择 -->
    <el-dialog :visible.sync="choseStaffFlag" title="人员选择" width="20%" append-to-body>
      <Staff ref="staffchoose" :chose-staff-flag="choseStaffFlag" :staff-list="staffList" :obj="currentObj" />
      <div slot="footer">
        <el-button class="btn-info" @click="choseStaffFlag = false">取消</el-button>
        <el-button class="btn-submit" type="primary" @click="choseStaffConfirm">确定</el-button>
      </div>
    </el-dialog>
  </div>
</template>
<script>
  import ObjInfoForm from './object/objectInfoForm.vue'
  import TargrtChoose from './object/targetChoose.vue'
  import Staff from './object/staffChoose.vue'

  import { Project } from '@/api/pro/index.js'
  export default {
    name: 'EvaluationObj',
    components: {
      ObjInfoForm,
      TargrtChoose,
      Staff
    },
    props: {
      basicInfo: {
        type: Object,
        default: null
      }
    },
    data() {
      return {
        proInfo: {},

        addObjFlag: false,
        objTitle: '',
        objInfo: {},

        objTableData: [],
        currentObj: {},

        choseTargetFlag: false, //no use

        choseStaffFlag: false,
        staffList: []
      }
    },
    computed: {},
    watch: {
      basicInfo: {
        immediate: true,
        handler(val, oldval) {
          this.loadUsers(val)
          this.proInfo = val
        },
        deep: true
      },
      proInfo: {
        immediate: true,
        handler(val, oldval) {
          if (val.id) {
            this.loadData(val.id)
          } else {
            this.objTableData = []
          }
        },
        deep: true
      }
    },
    created() {},
    mounted() {},

    methods: {
      /**
       * @description 初始化加载方案列表数据
       * @author hu_ty
       * @since
       * @param {number} id 项目id
       *
       */
      loadData(id) {
        let data = {
          pageNum: 1,
          pageSize: 100,
          proId: id
        }
        Project.getAllScheme(data).then((res) => {
          let objTableData = []
          res.data.data.list.forEach((element) => {
            if (element.obj) {
              element.obj.sID = element.id
              element.obj.choseUser = element.userIds
            }
            objTableData.push(element.obj)
          })
          this.objTableData = objTableData
          //地图对象信息展示所有对象
          this.$emit('saveAddAreaFrom', this.objTableData)
        })
      },
      /**
       * @description 人员信息获取
       * @author hu_ty
       * @since
       * @param {object} data 项目信息内容
       *
       */
      loadUsers(data) {
        if (data.chooseUsers && data.chooseUsers.length > 0) {
          this.staffList = data.chooseUsers
        } else if (data.chooseUserIds && data.chooseUserIds != '') {
          let arr = data.chooseUserIds.split(',')
          Project.getAllUSer({ pageNum: 1, pageSize: 100 }).then((res) => {
            this.staffList = []
            arr.forEach((item) => {
              let response = res.data.data.list
              let i = response.find((user) => {
                return user.id == item
              })
              if (i) {
                this.staffList.push(i)
              }
            })
          })
        }
      },
      //接受父组件传值项目信息
      setBasicInfo(data) {
        this.proInfo = data
      },

      //点击添加对象
      addObj() {
        if (this.proInfo.id) {
          this.objInfo = {}
          this.addObjFlag = true
          this.objTitle = '新建对象'
          this.$emit('addMarkArea')
        } else {
          this.$message({
            message: '请先保存项目基础信息！',
            type: 'warning'
          })
        }
      },
      //取消
      objInfoCancel() {
        this.addObjFlag = false
        this.$emit('cancelAddAreaFrom')
      },
      // 添加评估对象地图信息
      addMapInfo() {
        //调用父组件 地图画面方法
        this.$emit('addMarkArea')
      },
      //确认按钮
      objInfoConfirm() {
        this.objInfo = this.$refs.objInfoForm.objInfo
        //保存对象
        Project.saveObj(this.objInfo).then((res) => {
          if (res.status == 200) {
            //判断是新增对象还是编辑对象
            if (this.objInfo.sID) {
              //编辑对象 保存
              //查询项目下所有方案
              let data = {
                pageNum: 1,
                pageSize: 860601,
                proId: this.proInfo.id
              }
              Project.getAllScheme(data).then((res) => {
                let objTableData = []
                res.data.data.list.forEach((element) => {
                  element.obj.sID = element.id
                  objTableData.push(element.obj)
                })
                this.objTableData = objTableData
                //地图对象信息展示所有对象
                this.$emit('saveAddAreaFrom2', this.objTableData)
              })
            } else {
              //保存 方案
              const param = {
                objId: res.data.data.id,
                proId: this.proInfo.id
              }
              Project.saveScheme(param).then((res) => {
                // 根据proId 调用接口查询项目下所有方案
                let data = {
                  pageNum: 1,
                  pageSize: 860601,
                  proId: this.proInfo.id
                }
                Project.getAllScheme(data).then((res) => {
                  let objTableData = []
                  res.data.data.list.forEach((element) => {
                    element.obj.sID = element.id
                    objTableData.push(element.obj)
                  })
                  this.objTableData = objTableData
                  //地图对象信息展示所有对象
                  this.$emit('saveAddAreaFrom', this.objTableData)
                })
              })
            }
          }
        })
        this.addObjFlag = false
      },

      // 更多 人员选择
      userSelect(data) {
        this.currentObj = data
        this.choseStaffFlag = true
      },
      // 人员选择 确认方法
      async choseStaffConfirm() {
        await this.$refs.staffchoose.choseStaffConfirm()
        console.log(this.$refs.staffchoose.userIds)
        this.currentObj.choseUser = this.$refs.staffchoose.userIds
        this.choseStaffFlag = false
      },

      //删除对象和其对应方案
      handleDelete(e) {
        Project.delObj(e.id).then((res) => {
          Project.delScheme(e.sID).then((res) => {
            let data = {
              pageNum: 1,
              pageSize: 100,
              proId: this.proInfo.id
            }
            Project.getAllScheme(data).then((res) => {
              let objTableData = []
              res.data.data.list.forEach((element) => {
                element.obj.sID = element.id
                objTableData.push(element.obj)
              })
              this.objTableData = objTableData
              //地图对象信息展示所有对象
              this.$emit('saveAddAreaFrom', this.objTableData)
            })
          })
        })
      },
      //编辑对象
      editObj(data) {
        this.objInfo = JSON.parse(JSON.stringify(data))
        this.addObjFlag = true
        this.objTitle = '编辑对象'
        //编辑地理信息
        this.$emit('editFeature', this.objInfo)
      },
      //新增检测点
      handleSite(row) {
        this.$emit('handleSite', row)
      },

      // ==================================

      //双击某一行
      dblclick(row) {
        this.$emit('dblclickEvaluationObj', row)
      },

      rltypeIcon(row) {
        return row.rlType === 1 ? '河流' : row.rlType === 2 ? '湖泊' : '水库'
      },
      cellStyle(data) {
        if (data.columnIndex == 1) {
          if (data.row.rlType == 1) {
            return 'color:rgba(65, 97, 235, 1)'
          } else if (data.row.rlType == 2) {
            return 'color:rgba(11, 219, 168, 1)'
          } else if (data.row.rlType == 3) {
            return 'color:rgba(255, 181, 64, 1);'
          }
        }
      },
      // no use
      handleCurrentChange(val) {
        console.log('<== 选中对象行 ==>', val)
      },
      // no use
      choseTarget(data) {
        this.choseTargetFlag = true
      },
      // no use
      choseTargetConfirm() {
        const choosedTarget = this.$refs.targrtChoose.selectedTarget
      },
      // no use   获取pro下的所有obj
      getObjsByProID() {
        //根据项目ID 调用接口查询所有对象 接口
        let data = {
          pageNum: 1,
          pageSize: 860601
        }
        Project.getAllObj(data).then((res) => {
          this.objTableData = res.data.data.list
          //地图对象信息展示所有对象
          //调用父组件 保存对象信息方法
          this.$emit('saveAddAreaFrom', this.objTableData)
        })
      }
    }
  }
</script>
<style lang="less" scoped>
  // .add-box {
  //   display: flex;
  //   justify-content: space-around;
  //   border: 1px dashed blue;
  //   cursor: pointer;
  //   color: blue;
  //   padding: 24px;
  // }
  .obj-info-page {
    /deep/.el-dialog__header {
      border-bottom: 1px solid #eee;
    }

    /deep/.el-dialog__footer {
      border-top: 1px solid #eee;
    }
  }

  .ObjFrom {
    width: 400px;
    height: 88%;
    background-color: white;
    position: fixed;
    top: 90px;
    right: 10px;
    z-index: 999;
    padding: 16px 16px 0;

    .objFromTitle {
      height: 32px;
      padding: 8px 0;
      color: rgba(0, 0, 0, 1);
      font-family: PingFang SC;
      font-weight: 700;
      font-size: 20px;
      position: relative;
      padding: 2px;

      .angle {
        position: absolute;
        top: 0;
        right: 0;
      }
    }
  }

  .edit-btn {
    color: rgba(0, 0, 0, 0.8);
    font-family: 'PingFang SC';
    font-size: 14px;
    margin-right: 16px;
  }
</style>
