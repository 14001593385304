<template>
  <div>
    <el-table :data="tableData" @selection-change="handleSelectionChange">
      <el-table-column type="selection" width="55"> </el-table-column>
      <el-table-column prop="targetName" label="指标名称" width="200"></el-table-column>
    </el-table>
  </div>
</template>
<script>
  export default {
    name: '',
    components: {},
    props: {},
    data() {
      return {
        tableData: [
          {
            id: 1,
            targetName: '饮用水水源地'
          }
        ],
        selectedTarget: []
      }
    },
    computed: {},
    watch: {},
    created() {},
    mounted() {},
    methods: {
      handleSelectionChange(val) {
        this.selectedTarget = val
      }
    }
  }
</script>
<style lang="less" scoped></style>
