<template>
  <div>
    <div class="title-box">
      <MyTitle :title="objInfo.name" />
    </div>
    <!-- 基础信息 -->
    <div class="site-info-form">
      <el-form ref="siteForm" :model="siteForm" size="small" label-width="100px">
        <el-row :gutter="20">
          <el-form-item label="名称：" prop="name">
            <el-input v-model="siteForm.name" placeholder="请输入名称：" clearable :style="{ width: '80%' }">
            </el-input>
          </el-form-item>
          <el-form-item label="经度：" prop="lon">
            <el-input v-model="siteForm.lon" placeholder="请输入经度：" clearable :style="{ width: '80%' }"> </el-input>
          </el-form-item>
          <el-form-item label="纬度：" prop="lat">
            <el-input v-model="siteForm.lat" placeholder="请输入纬度：" clearable :style="{ width: '80%' }"></el-input>
          </el-form-item>
        </el-row>
        <el-row :gutter="20">
          <el-form-item label="水功能区：" prop="waterFunFlag">
            <el-radio-group v-model="siteForm.waterFunFlag" size="medium">
              <el-radio
                v-for="(item, index) in isWaterOptions"
                :key="index"
                :label="item.value"
                :disabled="item.disabled"
                >{{ item.label }}</el-radio
              >
            </el-radio-group>
          </el-form-item>
          <el-row v-if="siteForm.waterFunFlag" :gutter="20">
            <el-col :offset="3">
              <el-form-item label="基准规范" prop="waterFunEvaluateVersion">
                <el-select
                  v-model="siteForm.waterFunEvaluateVersion"
                  placeholder="请选择基准规范"
                  clearable
                  :style="{ width: '60%' }"
                  @change="changeJi"
                >
                  <el-option
                    v-for="(item, index) in waterFunEvaluateVersionOptions"
                    :key="index"
                    :label="item.label"
                    :value="item.value"
                    :disabled="item.disabled"
                  ></el-option>
                </el-select>
              </el-form-item>
            </el-col>

            <el-col :offset="3">
              <el-form-item label="水功能区" prop="waterFunEvaluateId">
                <el-select
                  v-model="siteForm.waterFunEvaluateId"
                  filterable
                  placeholder="请选择水功能区"
                  clearable
                  :style="{ width: '60%' }"
                >
                  <el-option
                    v-for="(item, index) in field114Options"
                    :key="index"
                    :label="item.waterAreaName"
                    :value="item.id"
                    :disabled="item.disabled"
                  ></el-option>
                </el-select>
              </el-form-item>
            </el-col>

            <el-col v-if="objInfo.rlType == 1" :offset="3">
              <el-form-item label="代表长度" prop="waterFunLength">
                <el-input
                  v-model.number="siteForm.waterFunLength"
                  type="number"
                  placeholder="请输入代表长度"
                  clearable
                  :style="{ width: '60%' }"
                >
                </el-input>
                m
                <el-tooltip content="河道在该水功能区的代表长度" placement="top">
                  <i class="el-icon-question"></i>
                </el-tooltip>
              </el-form-item>
            </el-col>

            <el-col v-else :offset="3">
              <el-form-item label="代表面积" prop="waterFunArea">
                <el-input
                  v-model.number="siteForm.waterFunArea"
                  type="number"
                  placeholder="请输入代表面积"
                  clearable
                  :style="{ width: '60%' }"
                >
                </el-input>
                m<sup>2</sup>
                <el-tooltip content="湖区在该水功能区的代表面积" placement="top">
                  <i class="el-icon-question"></i>
                </el-tooltip>
              </el-form-item>
            </el-col>
          </el-row>
        </el-row>
        <el-row v-if="objInfo.rlType == 1" :gutter="20">
          <el-form-item label="代表河长：" prop="delegateLength">
            <el-input
              v-model.number="siteForm.delegateLength"
              type="number"
              placeholder="请输入代表河长"
              clearable
              :style="{ width: '80%' }"
            >
            </el-input>
            m
          </el-form-item>
        </el-row>
      </el-form>
    </div>

    <!-- 指标 -->
    <div class="title-box">
      <MyTitle title="采样指标" />
    </div>
    <div>
      <div
        style="display: grid; grid-template-columns: 1.5fr 1fr 1fr 1fr 1fr; text-align: center; align-items: center"
        class="table-header-cls"
      >
        <div>指标名称</div>
        <div>频次</div>
        <div>周期</div>
        <div>备注</div>
        <div>操作</div>
      </div>

      <div
        style="
          display: grid;
          grid-template-columns: 1.5fr 1fr 1fr 1fr 1fr;
          column-gap: 5px;
          align-items: center;
          text-align: center;
          padding-top: 10px;
        "
      >
        <div style="font-size: 14px">水质优劣程度</div>
        <el-input v-model="siteForm.szylTimes" type="number"> </el-input>
        <el-input v-model="siteForm.szylCycle"> </el-input>
        <el-input v-model="siteForm.szylComment"> </el-input>
        <el-button type="text" @click.native.prevent="setTarget">设定指标</el-button>
      </div>

      <div
        style="
          display: grid;
          grid-template-columns: 1.5fr 1fr 1fr 1fr 1fr;
          column-gap: 5px;
          align-items: center;
          text-align: center;
          padding-top: 10px;
        "
      >
        <div style="font-size: 14px">底泥污染状况</div>
        <el-input v-model="siteForm.dnwrTimes" type="number"> </el-input>
        <el-input v-model="siteForm.dnwrCycle"> </el-input>
        <el-input v-model="siteForm.dnwrComment"> </el-input>
        <div></div>
      </div>

      <div
        style="
          display: grid;
          grid-template-columns: 1.5fr 1fr 1fr 1fr 1fr;
          column-gap: 5px;
          align-items: center;
          text-align: center;
          padding-top: 10px;
        "
      >
        <div style="font-size: 14px">底栖生物指数</div>
        <el-input v-model="siteForm.dqswTimes" type="number"> </el-input>
        <el-input v-model="siteForm.dqswCycle"> </el-input>
        <el-input v-model="siteForm.dqswComment"> </el-input>
        <div></div>
      </div>

      <div
        style="
          display: grid;
          grid-template-columns: 1.5fr 1fr 1fr 1fr 1fr;
          column-gap: 5px;
          align-items: center;
          text-align: center;
          padding-top: 10px;
        "
      >
        <div style="font-size: 14px">浮游植物</div>
        <el-input v-model="siteForm.fyzwTimes" type="number"> </el-input>
        <el-input v-model="siteForm.fyzwCycle"> </el-input>
        <el-input v-model="siteForm.fyzwComment"> </el-input>
        <div></div>
      </div>

      <div
        style="
          display: grid;
          grid-template-columns: 1.5fr 1fr 1fr 1fr 1fr;
          column-gap: 5px;
          align-items: center;
          text-align: center;
          padding-top: 10px;
        "
      >
        <div style="font-size: 14px">着生藻类</div>
        <el-input v-model="siteForm.zszlTimes" type="number"> </el-input>
        <el-input v-model="siteForm.zszlCycle"> </el-input>
        <el-input v-model="siteForm.zszlComment"> </el-input>
        <div></div>
      </div>

      <!-- <el-table :data="indexTableData" :row-style="rowStyle">
        <el-table-column prop="targetName" label="指标名称"></el-table-column>
        <el-table-column prop="times" label="频次">
          <template slot-scope="scope">
            <el-input v-model="scope.row.times" type="number"></el-input>
          </template>
        </el-table-column>
        <el-table-column prop="around" label="周期">
          <template slot-scope="scope">
            <el-input v-model="scope.row.around" type="text"></el-input>
          </template>
        </el-table-column>
        <el-table-column prop="comments" label="备注">
          <template slot-scope="scope">
            <el-tooltip class="item" effect="dark" :content="scope.row.comments" placement="top"> </el-tooltip>
            <el-input v-model="scope.row.comments" type="text"></el-input>
          </template>
        </el-table-column>
        <el-table-column label="操作">
          <template slot-scope="scope">
            <el-button v-if="scope.row.setTargetFlag" type="text" @click.native.prevent="setTarget(scope.row)"
              >设定指标</el-button
            >
          </template>
        </el-table-column>
      </el-table> -->
    </div>

    <div style="float: right; margin-right: 20px; padding-top: 20px; padding-bottom: 20px">
      <el-button class="btn-info" @click="cancelAddPoint">取消</el-button>
      <el-button v-if="siteForm.id" class="btn-info" type="danger" @click="delSite">删除</el-button>
      <el-button class="btn-submit" size="primary" @click="saveSiteInfo">保存</el-button>
    </div>

    <!-- 设定指标 -->
    <el-dialog v-if="choseTargetFlag" :visible.sync="choseTargetFlag" title="设定指标" width="20%" append-to-body>
      <div>
        <el-table
          ref="targetDataList"
          width="100%"
          :data="targetDataList"
          header-cell-class-name="table-header-cls"
          @selection-change="handleSelectionChange"
        >
          <el-table-column type="selection" width="55"> </el-table-column>
          <el-table-column prop="name" label="检测指标项"></el-table-column>
        </el-table>
      </div>
      <div slot="footer">
        <el-button class="btn-info" @click="choseTargetFlag = false">取消</el-button>

        <el-button class="btn-submit" type="primary" @click="choseTargetConfirm">确定</el-button>
      </div>
    </el-dialog>
  </div>
</template>
<script>
  import { Project } from '@/api/pro/index.js'
  import MyTitle from '@/components/title'

  export default {
    name: 'SiteForm',
    components: {
      MyTitle
    },
    props: {},
    data() {
      return {
        rowStyle: { height: 5 + 'px' },
        proId: null, //项目ID
        objInfo: {}, //站点的对象信息
        siteForm: {},

        //是否是水功能区
        isWaterOptions: [
          {
            label: '是',
            value: true
          },
          {
            label: '否',
            value: false
          }
        ],

        //水功能基准
        waterFunEvaluateVersionOptions: [],
        //水功能列表
        field114Options: [],

        // 采样指标 no use
        indexTableData: [
          {
            targetName: '水质优劣程度',
            setTargetFlag: 1,
            times: null,
            around: '',
            comments: ''
          },
          {
            targetName: '底泥污染状况',
            setTargetFlag: 0,
            times: null,
            around: '',
            comments: ''
          },
          {
            targetName: '底栖生物指数',
            setTargetFlag: 0,
            times: null,
            around: '',
            comments: ''
          },
          {
            targetName: '浮游植物',
            setTargetFlag: 0,
            times: null,
            around: '',
            comments: ''
          },
          {
            targetName: '着生藻类',
            setTargetFlag: 0,
            times: null,
            around: '',
            comments: ''
          }
        ],
        // 水质优劣 设定指标 dialog
        choseTargetFlag: false,
        targetDataList: [
          {
            name: '溶解氧',
            id: 1
          },
          {
            name: '氨氮',
            id: 2
          },
          {
            name: '总磷',
            id: 3
          },
          {
            name: '总氮',
            id: 4
          },
          {
            name: '高锰酸盐指数',
            id: 5
          },
          {
            name: '叶绿素',
            id: 6
          },
          {
            name: '透明度',
            id: 7
          }
        ],

        // 水质优劣 设定指标 已选的
        choosenTargetList: []
      }
    },
    computed: {},
    watch: {
      siteForm: {
        immediate: true,
        handler(val) {
          // if (val.szjcItems) {
          //   alert('szjcitems')
          //   const items = val.szjcItems.split(',')
          //   console.log('<==  ==>', this.targetDataList)
          //   this.$nextTick(() => {
          //     this.targetDataList.forEach((row) => {
          //       let str = row.id + ''
          //       console.log('<==  ==>', items.includes(str), this.$refs.targetDataList)
          //       if (items.includes(str)) {
          //         this.$refs.targetDataList.toggleRowSelection(row, true)
          //       } else {
          //         this.$refs.targetDataList.toggleRowSelection(row, false)
          //       }
          //     })
          //   })
          // }
        }
      }
    },
    created() {},
    mounted() {
      this.getjiZhunList()
      this.changeJi(1)
    },
    methods: {
      /**
       * @description 初始化采样指标频次数据
       * @author hu_ty
       * @since
       * @param {*}
       *
       */
      initData() {
        this.siteForm = {
          szylTimes: 4,
          szylCycle: '年',
          szylComment: null,
          dnwrTimes: 1,
          dnwrCycle: '年',
          dnwrComment: null,
          dqswTimes: 4,
          dqswCycle: '年',
          dqswComment: null,
          fyzwTimes: 4,
          fyzwCycle: '年',
          fyzwComment: null,
          zszlTimes: 4,
          zszlCycle: '年'
          // zszlComment: null
          // szjcItems:''
        }
      },
      //取消
      cancelAddPoint() {
        this.$emit('cancelAddPoint')
      },
      //保存监测点基本信息
      saveSiteInfo() {
        let szjcItems = []
        this.choosenTargetList.forEach((i) => {
          szjcItems.push(i.id)
        })

        this.siteForm.objId = this.objInfo.id
        this.siteForm.schemeId = this.objInfo.sID
        this.siteForm.proId = this.proId
        this.siteForm.szjcItems = szjcItems.toString()

        //保存监测点
        Project.saveJianCeDian(this.siteForm).then((res) => {
          //根据objId 查询所有监测点
          let data = {
            pageNum: 1,
            pageSize: 860601,
            objId: this.objInfo.id
          }
          Project.getAllJianCeDian(data).then((res) => {
            console.log('getAllJianCeDian', res.data.data.list)
            //地图展示监测点
            this.$emit('saveSiteInfo', res.data.data.list)
          })
        })
      },

      //删除监测点
      delSite() {
        this.$confirm('确认要删除此监测点信息吗？')
          .then((_) => {
            this.$confirm('确认删除此监测点信息并删除相关的指标填报数据？')
              .then((_) => {
                console.log(this.siteForm)
                Project.delJianCeDian(this.siteForm.id).then((res) => {
                  console.log('delJianCeDian', res)
                  if (res.data.code === 0) {
                    console.log('删除成功')

                    //根据proId 查询所有监测点
                    let data = {
                      pageNum: 1,
                      pageSize: 860601,
                      proId: this.proId
                    }
                    Project.getAllJianCeDian(data).then((res) => {
                      //地图展示监测点
                      this.$emit('saveSiteInfo', res.data.data.list)
                    })
                  }
                })
              })
              .catch((_) => {})
          })
          .catch((_) => {})
      },
      // 水质优劣 设定指标
      setTarget(data) {
        this.choseTargetFlag = true
        console.log('this.siteForm.szjcItems', this.siteForm.szjcItems)

        if (this.siteForm.szjcItems) {
          const items = this.siteForm.szjcItems.split(',')
          this.$nextTick(() => {
            this.targetDataList.forEach((row) => {
              let str = row.id + ''
              if (items.includes(str)) {
                this.$refs.targetDataList.toggleRowSelection(row, true)
              } else {
                this.$refs.targetDataList.toggleRowSelection(row, false)
              }
            })
          })
        }
      },
      // 选择水质优劣 设定指标
      handleSelectionChange(val) {
        // console.log('handleSelectionChange', val)
        this.choosenTargetList = val
      },
      // 确定 水质优劣 设定指标
      choseTargetConfirm() {
        this.choseTargetFlag = false
      },

      //获取水功能区基准规范
      getjiZhunList() {
        Project.getjiZhunList().then((res) => {
          this.waterFunEvaluateVersionOptions = [
            {
              label: res.data.data['1'].name,
              value: '1'
            }
          ]
        })
      },
      //选择水功能区列表
      changeJi(e) {
        Project.getAlljiZhunItems(e).then((res) => {
          this.field114Options = res.data.data
        })
      }
    }
  }
</script>
<style lang="less" scoped>
  .site-title-box {
    margin-bottom: 8px;
    display: flex;
    justify-content: space-between;
    .site-name {
      font-weight: normal;
      padding: 10px 0 0 20px;
    }
  }
  .site-info-form {
    margin-bottom: 8px;
  }

  /* 取消滑动滚动条 */
  ::v-deep ::-webkit-scrollbar {
    display: none;
    scrollbar-width: none; /* firefox */
    -ms-overflow-style: none; /* IE 10+ */
  }

  ::v-deep .el-table .el-table__cell {
    padding: 5px 0 !important;
  }
</style>
