<template>
  <div id="previewmap">
    <!-- 地区河流搜索框 -->
    <!-- <div id="search">
      <el-autocomplete
        v-model="nameSearch"
        style="width: 300px"
        placeholder="请输入地区名称"
        :fetch-suggestions="querySearch"
        :trigger-on-focus="false"
        clearable
        @select="handleSelect"
      >
        <template slot-scope="{ item }">
          <div>
            <div class="search_title">{{ item.title }}</div>
            <span class="search_addr">{{ item.address }}</span>
          </div>
        </template>
      </el-autocomplete>
    </div> -->
    <!-- 工具箱 -->
    <!-- <div class="toolBox" @click="clickBox">
      <img
        class="toolBoxIcon"
        :src="
          checkedTool ? require('@/assets/icon/map/toolBox_selected.png') : require('@/assets/icon/map/toolBox.png')
        "
        alt
      />
      <div :style="checkedTool ? 'color:blue' : ''">工具箱</div>
    </div> -->
    <!-- 工具 -->
    <!-- <div v-show="checkedTool" class="tools">
      <div class="toolIcon" @click="measure('LineString')">
        <img
          class="toolBoxIcon"
          :src="
            checkedLength
              ? require('@/assets/icon/map/measure_length_selected.png')
              : require('@/assets/icon/map/measure_length.png')
          "
          alt
        />
        <div :style="checkedLength ? 'color:blue' : ''">测距</div>
      </div>
      <div class="toolIcon" @click="measure('Polygon')">
        <img
          class="toolBoxIcon"
          :src="
            checkedArea
              ? require('@/assets/icon/map/measure_area_selected.png')
              : require('@/assets/icon/map/measure_area.png')
          "
          alt
        />
        <div :style="checkedArea ? 'color:blue' : ''">测面</div>
      </div>
      <div class="toolIcon" @click="getLayer(0)">
        <img class="toolBoxIcon" src="@/assets/icon/map/layer.png" alt />
        <div :style="checkedLayer ? 'color:blue' : ''">图层</div>
      </div>
    </div> -->

    <!-- 图层 -->
    <!-- <div v-show="checkedLayer" class="layerTools">
      <div class="toolIcon" @click="getLayer(1)">矢量图</div>
      <div class="toolIcon" @click="getLayer(2)">卫星图</div>
    </div> -->
  </div>
</template>
<script>
  import 'ol/ol.css'
  import '@/assets/css/map.less'

  import { Feature } from 'ol'
  import WKT from 'ol/format/WKT'
  import VectorLayer from 'ol/layer/Vector'
  import Vector from 'ol/source/Vector'
  import Point from 'ol/geom/Point'
  import { Style, Circle, Fill, Stroke, Text } from 'ol/style'
  //Map
  import Map from '@/openlayers/map'
  import Layers from '@/openlayers/Layers'

  //api
  import { Administrative_Division_service, Place_name_search } from '@/api/map.js'
  export default {
    name: '',
    components: {},
    props: {
      title: {
        type: String,
        default: ''
      }
    },
    data() {
      return {
        //地区搜索
        nameSearch: '',
        //地图
        map: null,
        //经纬度地图定位
        x: 120.645728,
        y: 31.138525,


        showAddPointFrom: false, //展示新增坐标点
        newPoint: {}, //当前新增标记点对象
        newPoints: [], //新增标记点集合
        pointLayer: null, //新增标记点 图层
        pointsLayer: null, //标记点图层

        showAddLineFrom: false, //展示新增标记线
        newLine: {}, //当前新增标记线对象
        newLines: [], //新增标记线集合
        newLineLayer: null, //新增标记线 图层
        linesLayer: null, //标记线图层

        showAddAreaFrom: false, //展示新增坐标面
        newAreas: [], //新增标记点集合
        newAreaLayer: null, //新增标记面 图层
        areasLayer: null, //标记面图层

        //工具
        checkedTool: false, //工具箱
        checkedLength: false, //测距中
        checkedArea: false, //侧面中
        checkedLayer: false
      }
    },
    computed: {},
    watch: {},
    created() {},
    mounted() {
      console.log('123231212');
      this.initalMap('previewmap')
    },
    methods: {
      /**
       * 初始化地图、设置中心点坐标
       */
      initalMap(id) {
        //初始化数据
        Map.sendThis(this)
        //初始化图层 天地图-矢量底图和矢量标记图
        const layers = new Layers()
        this.layers = layers.getLayers()
      
        //创建地图
        Map.createBaseMap(id)

        const code = '320600' // 南通行政编码
        // Administrative_Division_service(code).then((res) => {
        //   if (res.status === 200) {
        //     //设置经纬度
        //     const place = res.data.result[0]
        //     const l = place[0]
        //     this.x = l.location.lng
        //     this.y = l.location.lat

        //     this.map.getView().animate({
        //       // 只设置需要的属性即可
        //       center: [this.x, this.y], // 中心点
        //       zoom: 14, // 缩放级别
        //       rotation: undefined, // 缩放完成view视图旋转弧度
        //       duration: 1000 // 缩放持续时间，默认不需要设置
        //     })
        //   }
        // })
      },
      /**
       * 地图地区搜索
       */
      querySearch(queryString, cb) {
        this.nameSearch = queryString
        Place_name_search(queryString, this.x, this.y).then((res) => {
          if (res.status === 200) {
            cb(res.data.data)
          }
        })
      },
      /**
       * 选择搜索地区，设置新的地图中心点
       */
      handleSelect(item) {
        this.map.getView().animate({
          // 只设置需要的属性即可
          center: [item.location.lng, item.location.lat], // 中心点
          zoom: 15, // 缩放级别
          rotation: undefined, // 缩放完成view视图旋转弧度
          duration: 1000 // 缩放持续时间，默认不需要设置
        })
      },
      /**
       * 测距、侧面
       */
      measure(type) {
        if (type === 'LineString') {
          this.checkedArea = false
          this.checkedLength = !this.checkedLength
          if (this.checkedLength) {
            this.statusFlag = true
          } else {
            this.statusFlag = false
          }
        } else {
          this.checkedLength = false
          this.checkedArea = !this.checkedArea
          if (this.checkedArea) {
            this.statusFlag = true
          } else {
            this.statusFlag = false
          }
        }
        //type：测距、侧面
        //statusFlag：选择操作、取消操作
        Map.measure(type, this.statusFlag)
      },
      //图层
      getLayer(e) {
        if (e === 0) {
          this.checkedLayer = !this.checkedLayer
          return
        }

        //矢量图
        if (e === 1) {
          this.layers[0].setVisible(true)
          this.layers[1].setVisible(false)
        }
        //卫星图
        if (e === 2) {
          this.layers[1].setVisible(true)
          this.layers[0].setVisible(false)
        }
      },

      clickBox() {
        this.checkedTool = !this.checkedTool
        if (!this.checkedTool) {
          this.checkedLayer = false
        }
      },

      // =============================下面是地图点、线、面操作方法=====================
      /*
       *
       *          ┌─┐       ┌─┐
       *       ┌──┘ ┴───────┘ ┴──┐
       *       │                 │
       *       │       ───       │
       *       │  ─┬┘       └┬─  │
       *       │                 │
       *       │       ─┴─       │
       *       │                 │
       *       └───┐         ┌───┘
       *           │         │
       *           │         │
       *           │         │
       *           │         └──────────────┐
       *           │                        │
       *           │                        ├─┐
       *           │                        ┌─┘
       *           │                        │
       *           └─┐  ┐  ┌───────┬──┐  ┌──┘
       *             │ ─┤ ─┤       │ ─┤ ─┤
       *             └──┴──┘       └──┴──┘
       *                  神兽
       */

      //点操作 Begin====================================================
      /**
       * 显示标记点
       * 经纬度
       */
      showMarkPoints(data) {
        // this.map.removeLayer(this.pointsLayer)
        let pointList = []
        data.forEach((item) => {
          const lon = item.lon
          const lat = item.lat
          const feature = new Feature({
            geometry: new Point([lon, lat])
          })
          feature.setProperties(item)
          // let styleHh = new Style({
          //   image: new Icon({
          //     src: require('@/assets/icon/map/marker_icon.png'),
          //     anchor: [0.5, 1],
          //     fill: new Fill({
          //       color: '#f40'
          //     })
          //   }),
          //   fill: new Fill({
          //     color: '#eee'
          //   }),
          //   text: new Text({
          //     text: '监测点',
          //     font: '14px font-size',
          //     fill: new Fill({
          //       // 设置字体颜色
          //       color: '#000'
          //     }),
          //     offsetY: 10 // 设置文字偏移量
          //   })
          // })

          let styleHh = new Style({
            // 点样式
            image: new Circle({
              // 点半径
              radius: 10,
              // 点的边框，
              stroke: new Stroke({
                color: 'rgba(65, 97, 235,1)',
                width: 1
              }),
              // 缩放比
              scale: 1,
              // 填充色
              fill: new Fill({
                color: 'rgba(5, 97, 235,0.6)'
              })
            }),
            text: new Text({
              text: item.name,
              font: '14px font-size',
              fill: new Fill({
                // 设置字体颜色
                color: '#000'
              }),
              offsetY: 18 // 设置文字偏移量
            })
          })
          feature.setStyle(styleHh)
          pointList.push(feature)
        })
        this.pointsLayer = new VectorLayer({
          source: new Vector({
            features: [...pointList]
          })
        })
        this.pointsLayer.setZIndex(4)
        this.map.addLayer(this.pointsLayer)
      },

      /**
       * 地图显示标记面
       */
      showMarkArea(data) {
        this.map.removeLayer(this.areasLayer)

        let areaList = []
        data
          .filter((i) => i.geom)
          .forEach((item) => {
            let wkt = item.geom
            let format = new WKT()
            let feature = format.readFeature(wkt, {
              dataProjection: 'EPSG:4326',
              featureProjection: 'EPSG:4326'
            })
            let areaStyle = new Style({
              //填充样式
              fill: new Fill({
                color: 'rgba(5, 97, 235,0.6)'
              }),
              //边框样式
              stroke: new Stroke({
                color: 'rgba(65, 97, 235,1)',
                width: 3
              }),
              text: new Text({
                text: item.name,
                font: '18px sans-serif',
                fill: new Fill({
                  // 设置字体颜色
                  color: '#000'
                })
                //   offsetY: 10 // 设置文字偏移量
              })
            })
            feature.setStyle(areaStyle)
            feature.setProperties(item)
            areaList.push(feature)
          })
        this.areasLayer = new VectorLayer({
          source: new Vector({
            features: [...areaList]
          })
        })
        this.map.addLayer(this.areasLayer)
      }
    }
  }
</script>
<style lang="less" scoped></style>
