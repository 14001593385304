<template>
  <div class="pro-basic-form">
    <el-form ref="myForm" :model="basicForm" size="small" label-width="80px">
      <el-form-item label="评估年份:" prop="year">
        <span v-if="!editFlag">{{ basicForm.year }}</span>
        <el-date-picker
          v-else
          v-model="basicForm.year"
          type="year"
          placeholder="选择年"
          :style="{ width: '80%' }"
          format="yyyy"
          value-format="yyyy"
        >
        </el-date-picker>
      </el-form-item>
      <el-form-item label="地区:" prop="region">
        <span v-if="!editFlag">{{ basicForm.region }}</span>
        <el-input
          v-else
          v-model="basicForm.region"
          placeholder="请输入地区"
          clearable
          :style="{ width: '80%' }"
        ></el-input>
      </el-form-item>
      <el-form-item label="标准规范:" prop="standardId">
        <span v-if="!editFlag">{{ formatStand(basicForm.standardId, 1) }}</span>
        <el-select
          v-else
          v-model="basicForm.standardId"
          placeholder="请输入标准规范"
          clearable
          :style="{ width: '80%' }"
        >
          <el-option
            v-for="(item, index) in standardIdOptions"
            :key="index"
            :label="item.label"
            :value="item.value"
            :disabled="item.disabled"
          ></el-option>
        </el-select>
      </el-form-item>

      <el-form-item label="成员:">
        <span v-if="!editFlag">{{ userName }}</span>
        <el-row v-else>
          <el-col :span="20">
            <el-tag
              v-for="tag in basicForm.chooseUsers"
              :key="tag.id"
              closable
              type="info"
              size="medium"
              @close="closeTag(tag)"
            >
              {{ tag.name }}
            </el-tag>
          </el-col>
          <el-col :span="4"><el-button icon="el-icon-plus" @click="userInfo"></el-button></el-col>
        </el-row>
      </el-form-item>
    </el-form>

    <el-dialog title="成员列表" :visible.sync="userInfoDialogFlag" width="30%" append-to-body>
      <el-table ref="multipleTable" :data="userTableData" @selection-change="selectUser">
        <el-table-column type="selection" width="55"> </el-table-column>
        <el-table-column prop="name" label="姓名" width="200"></el-table-column>
        <el-table-column fixed="right" label="操作">
          <template slot-scope="scope">
            <!-- <el-button type="text" size="small" @click="editUser(scope.row)">编辑</el-button> -->
            <el-button v-if="scope.row.username != 'admin'" type="text" size="small" @click="delUser(scope.row)"
              >删除</el-button
            >
          </template>
        </el-table-column>
      </el-table>
      <div slot="footer" class="dialog-footer">
        <el-button
          type="text"
          icon="el-icon-plus"
          style="float: left"
          @click="
            addUserDialogFlag = true
            userForm = {}
          "
          >添加成员</el-button
        >
        <el-button class="btn-info" @click="userInfoDialogFlag = false">取 消</el-button>
        <el-button class="btn-submit" type="primary" @click="userSubmit">确 定</el-button>
      </div>
    </el-dialog>

    <el-dialog title="成员信息" :visible.sync="addUserDialogFlag" width="20%" append-to-body>
      <el-form ref="userForm" :model="userForm" :rules="userFormRules" size="small" label-width="100px">
        <el-form-item label="账号" prop="username">
          <el-input v-model="userForm.username" placeholder="请输入账号" clearable :style="{ width: '80%' }"></el-input>
        </el-form-item>
        <el-form-item label="密码" prop="pwd">
          <el-input
            v-model="userForm.pwd"
            placeholder="请输入密码"
            clearable
            show-password
            :style="{ width: '80%' }"
          ></el-input>
        </el-form-item>
        <el-form-item label="真实姓名" prop="name">
          <el-input v-model="userForm.name" placeholder="请输入真实姓名" clearable :style="{ width: '80%' }">
          </el-input>
        </el-form-item>
        <el-form-item label="电话" prop="tel">
          <el-input v-model="userForm.tel" placeholder="请输入电话" clearable :style="{ width: '80%' }"></el-input>
        </el-form-item>
      </el-form>
      <div slot="footer">
        <el-button class="btn-info" @click="addUserDialogFlag = false">取消</el-button>
        <el-button class="btn-submit" type="primary" @click="handelUserConfirm">确定</el-button>
      </div>
    </el-dialog>
  </div>
</template>
<script>
  import { mapState, mapMutations } from 'vuex'

  import { Project } from '@/api/pro/index.js'

  export default {
    name: 'ProBasicForm',
    components: {},
    props: {
      basicInfo: {
        type: Object,
        default: () => {}
      },
      editFlag: {
        type: Boolean,
        default: false
      }
    },
    data() {
      return {
        userName: '', // 非编辑状态的人员信息展示
        basicForm: {
          name: '',
          region: '',
          standardId: '',
          year: null,
          chooseUsers: []
        },

        // 标准规范
        standardIdOptions: [],

        userForm: {},
        userInfoDialogFlag: false,
        userTableData: [],

        addUserDialogFlag: false,
        userFormRules: {
          username: [
            {
              required: true,
              message: '请输入账号',
              trigger: 'blur'
            }
          ],
          pwd: [
            {
              required: true,
              message: '请输入密码',
              trigger: 'blur'
            }
          ],
          name: [
            {
              required: false,
              message: '请输入真实姓名',
              trigger: 'blur'
            }
          ],
          tel: [
            {
              required: false,
              pattern: /^(?:(?:\+|00)86)?1[3-9]\d{9}$/,
              message: '请输入正确的手机号码',
              trigger: 'blur'
            }
          ]
        },

        multipleSelectionUser: []
      }
    },
    computed: {
      ...mapState({})
    },
    watch: {
      basicInfo: {
        handler(val) {
          this.basicForm = JSON.parse(JSON.stringify(val))
          if (val.chooseUserIds) {
            this.loadUser(val.chooseUserIds)
          }
        }
      }
    },
    created() {
      // 查询标准规范
      this.getStandardIdOptions()
    },
    mounted() {},
    methods: {
      // 查询所标准规范
      getStandardIdOptions() {
        Project.guideList().then((res) => {
          const data = res.data.data['1']
          this.standardIdOptions = [
            {
              value: 1,
              label: data.name
            }
          ]
        })
      },
      /**
       * @description 展示信息非编辑是页面数据的处理（部分id数据转化成文字数据）
       * @author hu_ty
       * @since
       * @param {string | number} val 需要转化的数据
       * @param {number} type 判定是哪一个数据需要转化（1 ==> 评价标准；2 ==> 项目人员）
       *
       */
      formatStand(val, type) {
        if (type === 1) {
          const i = this.standardIdOptions.find((i) => {
            return i.value == val
          })
          if (i) {
            return i.label
          }
        }
      },
      /**
       * @description  通过id字符串获取成员列表
       * @author hu_ty
       * @since
       * @param {string} val pro 中的 chooseUserIds
       *
       */
      loadUser(val) {
        let arr = val.split(',')
        Project.getAllUSer({ pageNum: 1, pageSize: 100 }).then((res) => {
          this.userTableData = res.data.data.list
          this.basicForm.chooseUsers = []
          arr.forEach((choId) => {
            const i = this.userTableData.find((i) => {
              return choId == i.id
            })
            this.basicForm.chooseUsers.push(i)
          })
          if (this.basicForm.chooseUsers.length > 0) {
            this.userName = ''
            this.basicForm.chooseUsers.forEach((item) => {
              this.userName = this.userName + item.name + ' '
            })
          }
        })
      },
      //点击 成员+
      userInfo() {
        this.userInfoDialogFlag = true
        this.getAllUser()
      },
      //查询所有成员信息
      getAllUser() {
        let data = {
          pageNum: 1,
          pageSize: 100
        }
        Project.getAllUSer(data).then((res) => {
          this.userTableData = res.data.data.list
          //回写已选择项
          this.$nextTick(() => {
            this.userTableData.forEach((row) => {
              this.basicForm.chooseUsers.forEach((i) => {
                if (i.id === row.id) {
                  this.$refs.multipleTable.toggleRowSelection(row, true)
                }
              })
            })
          })
        })
      },
      //修改、添加成员
      handelUserConfirm() {
        let param = this.userForm
        param.type = 'sampling'
        this.$refs['userForm'].validate((valid) => {
          if (valid) {
            Project.addUSer(param).then((res) => {
              this.getAllUser()
            })
            this.addUserDialogFlag = false
          } else {
            console.log('error submit!!')
            return false
          }
        })
      },
      //点击修改成员
      editUser(data) {
        this.addUserDialogFlag = true
        this.userForm = data
      },
      //删除成员
      delUser(data) {
        Project.delUSer(data.id).then((res) => {
          this.getAllUser()
        })
      },
      //选择成员用户
      selectUser(val) {
        this.multipleSelectionUser = val
      },
      //确认成员用户选择
      userSubmit() {
        this.basicForm.chooseUsers = JSON.parse(JSON.stringify(this.multipleSelectionUser))
        this.userInfoDialogFlag = false
      },
      //tag成员标签删除功能  删除后需再次保存
      closeTag(data) {
        this.basicForm.chooseUsers.splice(this.basicForm.chooseUsers.indexOf(data), 1)
      }
    }
  }
</script>
<style lang="less" scoped>
  .pro-basic-form {
    /deep/.el-dialog__header {
      border-bottom: 1px solid #eee;
    }
    /deep/.el-dialog__footer {
      border-top: 1px solid #eee;
    }
  }
</style>
