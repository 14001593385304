<template>
  <div>
    <!-- <div style="width: 100%; text-align: center">
      <h1>{{ proInfo.name }}</h1>
      <h4>(采样方案)</h4>
    </div> -->

    <div class="preview-body">
      <div class="river-info-box">
        <div class="title-box">
          <MyTitle title="河流" />
        </div>
        <div class="body-box">
          <el-table :data="riverTableData" height="400px" header-cell-class-name="table-header-cls">
            <!-- <el-table-column type="index" label="序号" width="50"> </el-table-column> -->
            <el-table-column prop="name" label="对象">
              <template v-slot="scope">
                <div>{{ scope.row.obj.name }}</div>
              </template>
            </el-table-column>
            <el-table-column prop="siteNum" label="监测点" width="80"></el-table-column>
            <el-table-column prop="staffs" label="工作人员">
              <template v-slot="scope">
                {{ loadStaff(scope.row) }}
              </template>
            </el-table-column>
          </el-table>
        </div>
        <div class="body-box">
          <div class="title-box">
            <MyTitle title="采样频次" />
          </div>
          <table width="auto" style="text-align: center; border-collapse: collapse">
            <tr style="background-color: #c4e1ff">
              <td
                v-for="(item, index) in targetList"
                :key="index"
                style="max-width: 100px; text-align: center; color: rgba(0, 0, 0, 0.9); font-size: 14px; padding: 6px"
              >
                <!-- <div style="color: rgba(0, 0, 0, 0.9); font-size: 14px; padding: 6px"> -->
                {{ item.name }}
                <!-- </div> -->
              </td>
            </tr>
            <tr>
              <td v-for="(item, index) in targetList" :key="index" style="max-width: 100px; text-align: center">
                <div style="color: rgba(0, 0, 0, 1); font-size: 16px; padding: 6px 6px">{{ item.value }}</div>
              </td>
            </tr>
          </table>
        </div>
      </div>
      <!-- <div class="map-info-box"> -->
      <PreviewMap ref="mapIndicatorEva" class="map-info-box"></PreviewMap>
      <!-- </div> -->
      <div class="lake-info-box">
        <div class="title-box">
          <MyTitle title="湖泊(水库)" />
        </div>
        <div class="body-box">
          <el-table :data="lakeTableData" height="400px" header-cell-class-name="table-header-cls">
            <!-- <el-table-column type="index" label="序号" width="50"> </el-table-column> -->
            <el-table-column prop="name" label="对象">
              <template v-slot="scope">
                <div>{{ scope.row.obj.name }}</div>
              </template>
            </el-table-column>
            <el-table-column prop="siteNum" label="监测点" width="80"></el-table-column>
            <el-table-column prop="staffs" label="工作人员">
              <template v-slot="scope">
                {{ loadStaff(scope.row) }}
              </template>
            </el-table-column>
          </el-table>
        </div>
        <div class="body-box">
          <div class="title-box">
            <MyTitle title="采样频次" />
          </div>
          <!-- <div class="title-name-single">频次</div> -->
          <table width="auto" style="text-align: center; border-collapse: collapse">
            <tr style="background-color: #c4e1ff">
              <td
                v-for="(item, index) in lakeTargetList"
                :key="index"
                style="max-width: 100px; text-align: center; color: rgba(0, 0, 0, 0.9); font-size: 14px; padding: 6px"
              >
                <!-- <div style="color: rgba(0, 0, 0, 0.9); font-size: 14px; padding: 6px"> -->
                {{ item.name }}
                <!-- </div> -->
              </td>
            </tr>
            <tr>
              <td v-for="(item, index) in lakeTargetList" :key="index" style="max-width: 100px; text-align: center">
                <div style="color: rgba(0, 0, 0, 1); font-size: 16px; padding: 6px 6px">{{ item.value }}</div>
              </td>
            </tr>
          </table>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
  import MyTitle from '@/components/title'
  import { Project } from '@/api/pro/index.js'
  import { Site } from '@/api/scheme/index.js'

  import PreviewMap from '../createProject/mapPreview.vue'

  export default {
    name: '',
    components: {
      MyTitle,
      PreviewMap
    },
    props: {
      proInfo: {
        type: Object,
        default: () => {}
      }
    },
    data() {
      return {
        userTableData: [],
        objTableData: [], // 方案对象信息list
        riverTableData: [],
        lakeTableData: [],
        targetList: [
          {
            name: '水质优劣程度',
            value: 4
          },
          {
            name: '底泥污染状况',
            value: 1
          },
          {
            name: '底栖生物指数',
            value: 4
          },
          {
            name: '浮游植物',
            value: 4
          },
          {
            name: '着生藻类',
            value: 4
          }
        ],
        lakeTargetList: [
          {
            name: '水质优劣程度',
            value: 4
          },
          {
            name: '底泥污染状况',
            value: 1
          },
          {
            name: '营养状态指数',
            value: 4
          },
          {
            name: '底栖生物指数',
            value: 4
          },
          {
            name: '蓝藻密度',
            value: 4
          }
        ]
      }
    },
    computed: {},
    watch: {
      proInfo: {
        immediate: true,
        handler(val) {
          console.log('<== preview ==>', val)
          this.loadData(val)
        }
      }
    },
    created() {},
    mounted() {
      this.loadUserData()
    },
    methods: {
      /**
       * @description
       * @author hu_ty
       * @since
       * @param {*}
       *
       */
      loadUserData() {
        Project.getAllUSer({ pageNum: 1, pageSize: 100 }).then((res) => {
          this.userTableData = res.data.data.list
        })
      },
      /**
       * @description 预览数据加载
       * @author hu_ty
       * @since
       * @param {object} data 项目基本信息（id：项目id ； name：项目名称）
       *
       */
      loadData(data) {
        // 通过项目id 获取相关对象信息列表
        this.loadSchemeData(data.id)
      },
      /**
       * @description  获取方案列表
       * @author hu_ty
       * @since
       * @param {number} id 项目id
       *
       */
      loadSchemeData(id) {
        let data = {
          pageNum: 1,
          pageSize: 100,
          proId: id
        }
        let that = this
        Project.getAllScheme(data).then((res) => {
          console.log('getAllScheme', res.data.data.list)
          let arr = res.data.data.list
          let lake = []
          let river = []

          arr.length > 0 &&
            arr.forEach((item) => {
              if (item.rlType == 1) {
                river.push(item)
              } else {
                lake.push(item)
              }
            })
          this.loadLakeInfo(lake)
          this.loadRiverInfo(river)
          //地图对象信息展示所有对象 todo
          let objTableData = []
          res.data.data.list.forEach((element) => {
            if (element.obj) {
              element.obj.sID = element.id
            }
            objTableData.push(element.obj)
          })
          this.objTableData = objTableData
          this.drawObj(this.objTableData)
        })
      },
      /**
       * @description  地图对象绘制
       * @author hu_ty
       * @since
       * @param {*}
       *
       */
      drawObj(data) {
        console.log(data)
        this.$refs.mapIndicatorEva.showMarkArea(data)
      },
      /**
       * @description 湖库信息展示
       * @author hu_ty
       * @since
       * @param {array} data 河湖方案列表
       *
       */
      loadLakeInfo(data) {
        data.length > 0 &&
          data.forEach(async (item) => {
            console.log('<==  ==>', item)
            item.siteNum = null
            item.siteNum = await Site.getList({ schemeId: item.id, pageSize: 100 }).then((res) => {
              let siteInfo = res.data.data.list
              return siteInfo.length
            })
          })
        this.lakeTableData = data
      },
      /**
       * @description 河流信息展示
       * @author hu_ty
       * @since
       * @param {array} data 河流方案列表
       *
       */
      loadRiverInfo(data) {
        data.length > 0 &&
          data.forEach(async (item) => {
            console.log('<==  ==>', item)
            item.siteNum = null
            item.siteNum = await Site.getList({ schemeId: item.id, pageSize: 100 }).then((res) => {
              let siteInfo = res.data.data.list
              return siteInfo.length
            })
          })
        this.riverTableData = data
      },
      /**
       * @description
       * @author hu_ty
       * @since
       * @param {*}
       *
       */
      loadStaff(data) {
        console.log('<==  ==>', data)
        let arr = data.userIds && data.userIds.split(',')
        let name = ''
        if (arr && arr.length > 0) {
          arr.forEach((choId) => {
            const i = this.userTableData.find((i) => {
              return choId == i.id
            })
            if (i) {
              name = name + i.name + ' '
            }
          })
        }
        return name
      }
    }
  }
</script>
<style lang="less" scoped>
  .preview-body {
    width: 100%;
    // height: 80%;
    display: flex;
    justify-content: space-around;
    padding: 0 0 48px 0;

    .river-info-box,
    .lake-info-box {
      width: 21%;
      background-color: #fff;
      // box-shadow: 2px 2px 10px #666;
      .body-box {
        // padding: 0 16px;
        .title-name {
          margin-top: -10px;
          padding: 4px;
          font-weight: 800;
          width: 200px;
          background-color: #fff;
        }
        .title-name-single {
          padding: 4px;
          font-weight: 800;
        }
      }
    }
    .map-info-box {
      overflow: hidden;

      position: relative;
      // z-index: 200;
      width: 50%;
      height: 600px;
    }
  }
  .title-box {
    margin: 8px 0;
    height: 40px;
    line-height: 24px;
    align-items: center;
    display: flex;
    padding: 0 16px;

    justify-content: space-between;
    background: rgba(64, 158, 255, 0.1);
    backdrop-filter: blur(33px);
  }

  /deep/ #search {
    left: 20px;
  }
  /deep/ .toolBox {
    left: 340px;
  }
  /deep/ .tools {
    left: 340px;
  }
  /deep/ .layerTools {
    left: 440px;
  }
</style>
